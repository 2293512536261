<template>
  <Modal
    name="temporarily_unavailable"
    title="Акция завершена"
    @closeModal="closeModal"
  >
    <template v-slot:content>
      <div class="success_modal">
        <div class="modal__subtitle">
          <div>
            Cпасибо за&nbsp;участие! <br />Вручение призов осуществляется
            по&nbsp;31&nbsp;октября 2024&nbsp;<span
              style="text-transform: lowercase"
              >г</span
            >.
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
  components: {
    Modal,
  },
  data: function () {
    return {};
  },
  methods: {
    closeModal(name) {
      this.$modal.hide(name);
    },
    beforeOpen() {},
  },
};
</script>
